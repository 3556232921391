[data-dapp-kit]:where(*), [data-dapp-kit] :where(*) {
  box-sizing: border-box;
  color: var(--dapp-kit-colors-body);
  font-family: var(--dapp-kit-typography-fontFamily);
  font-size: var(--dapp-kit-fontWeights-normal);
  font-style: var(--dapp-kit-typography-fontStyle);
  font-weight: var(--dapp-kit-fontWeights-normal);
  line-height: var(--dapp-kit-typography-lineHeight);
  letter-spacing: var(--dapp-kit-typography-letterSpacing);
}
[data-dapp-kit]:where(button), [data-dapp-kit] :where(button) {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  border: 0;
  padding: 0;
  margin: 0;
}
[data-dapp-kit]:where(a), [data-dapp-kit] :where(a) {
  text-decoration: none;
  color: inherit;
  outline: none;
}
[data-dapp-kit]:where(ol, ul), [data-dapp-kit] :where(ol, ul) {
  list-style: none;
  margin: 0;
  padding: 0;
}
[data-dapp-kit]:where(h1, h2, h3, h4, h5, h6), [data-dapp-kit] :where(h1, h2, h3, h4, h5, h6) {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}