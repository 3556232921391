import React from "react";
import { BrowserRouter as Router, Routes, Route,} from "react-router-dom";
import Staking from "./pages/staking/staking";
import StakingOne from "./pages/stakingOne/stakingOne";
import StakingTwo from "./pages/stakingTwo/stakingTwo";
import StakingThree from "./pages/stakingThree/stakingThree";
import Main from "./pages";
 
function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Main />} />
                <Route path="/staking" element={<Staking />} />
                <Route path="/staking1" element={<StakingOne />} />
                <Route path="/staking2" element={<StakingTwo />} />
                <Route path="/staking3" element={<StakingThree />} />
            </Routes>
        </Router>
    );
}

export default App;