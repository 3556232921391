import React from 'react';
import { useConnectWallet, useWallets, useCurrentAccount, useSignAndExecuteTransaction, useDisconnectWallet, useSuiClient, useSuiClientQuery } from "@mysten/dapp-kit";
import { useEffect, useState } from "react";
import { COIN_TYPE, PACKAGE_ID, STAKING_CONFIG_ID } from '../../config/constants';
import { Transaction } from "@mysten/sui/transactions";

import './staking.css'

function Staking() {
  const [butDisplay, setButDisplay] = useState("none")
  const [wallet, setWallet] = useState("Wallet")
  const [stakeId, setStakeId] = useState('0')
  const [stakedFuki, setStakedFuki] = useState("0")
  const [percent, setPercent] = useState("-")
  const [reward, setReward] = useState("-")
  const [unlockTime, setUnlockTime] = useState("-")
  const [fukiBalance, setFukiBalance] = useState("0")
  const suiClient = useSuiClient()
  const { mutate: connect } = useConnectWallet();
  const { mutate: disconnect } = useDisconnectWallet();
  const wallets = useWallets();
  const currentAccount = useCurrentAccount();

  async function onWalletConnected() {
    setButDisplay("grid")
    if (currentAccount) {
      const wallet = currentAccount.address.substring(0, 8)
      setWallet(wallet)
    }
  }

  function onWalletDisconnected() {
    setButDisplay("none")
    setWallet("Wallet")
  }

  const { mutate: signAndExecute } = useSignAndExecuteTransaction({
    execute: async ({ bytes, signature }) =>
      await suiClient.executeTransactionBlock({
        transactionBlock: bytes,
        signature,
        options: {
          showRawEffects: true,
          showEffects: true,
        },
      }),
  });

  const totalBalance = useSuiClientQuery("getBalance", {
    owner: currentAccount?.address || '',
    coinType: COIN_TYPE
  }, { enabled: currentAccount != null }).data?.totalBalance

  const stakeDatas = useSuiClientQuery("getOwnedObjects", {
    owner: currentAccount?.address || '',
    filter: {
      StructType: `${PACKAGE_ID}::staking::Stake`
    },
    options: {
      showContent: true,
      showOwner: true,
    },
  }, { enabled: currentAccount != null }).data?.data
  let filteredAndSortedStakeDatas
  let totalStake
  let stakeInfo
  if (stakeDatas) {
    filteredAndSortedStakeDatas = stakeDatas
      .filter(item => item.data.content.fields.balance !== "0")
      .sort((a, b) => a.data.content.fields.unlock_time - b.data.content.fields.unlock_time);

    totalStake = filteredAndSortedStakeDatas
      .reduce((sum, item) => sum + parseFloat(item.data.content.fields.balance), 0);
    if (filteredAndSortedStakeDatas[0])
      stakeInfo = filteredAndSortedStakeDatas[0].data?.content
    
  }

  function withdraw() {

    if (!currentAccount) {
      alert("Please, connect your wallet")
      return
    }

    if (!stakeId) {
      alert("You don't have stake")
      return
    }
    const tx = new Transaction();

    let success = false
    tx.moveCall({
      arguments: [
        tx.object(stakeId.toString()),
        tx.object(STAKING_CONFIG_ID),
        tx.object('0x6')
      ],
      target: `${PACKAGE_ID}::staking::withdraw`,
    })

    signAndExecute(
      {
        transaction: tx
      },
      {
        onSuccess: (result) => {
          console.log(result)
          alert("You success unstake FUKI")
          success = true

        },
        onError: (error) => {
          console.log(error)
          success = false
        }
      }
    )
    return success
  }

  useEffect(() => {
    if (currentAccount) {
      const wallet = currentAccount.address.substring(0, 8)
      setWallet(wallet)
      setButDisplay('grid')
      setFukiBalance((totalBalance/1000000).toFixed(2))
    }
    if (stakeInfo) {
      if (stakeInfo.fields.balance != 0) {
        setStakedFuki((totalStake/1000000).toFixed(2))
        setPercent(stakeInfo.fields.percent)
        setReward((stakeInfo.fields.reward/1000000).toFixed(2))
        setStakeId(filteredAndSortedStakeDatas[0].data.objectId)
        const unlockTimeUTF = new Date(Number(stakeInfo.fields.unlock_time))
        const formattedDate = unlockTimeUTF.toLocaleString().slice(0, 17).replace("T", " ").replace(",", "");
        let date = formattedDate
        if (formattedDate[formattedDate.length - 1] == ":") {
        date = date.slice(0, -1);
        }
        setUnlockTime(date)
      }
    }
  })

  return (
    <>
      <a id="page-1" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
      <section id="LepCE1qH99a70ORi" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0 }}>
        <div id="oyFLJsABht87qRYA" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
          <div id="OqsN2dWNJz0dIZvP" style={{ zIndex: 0 }}>
            <div id="dgl6XvwnqmQROCq5" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="T7M8kmMlquwLmACh" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                <div id="vYlzenawnLF9443A" style={{ backgroundColor: '#9b7352', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}></div>
              </div>
            </div>
          </div>
          <div id="wKMkfaDmuqZ7EL4H" style={{ zIndex: 1 }}>
            <div id="gAAIF2rcVUR7Pnf3" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="uZv4qJBgMzBsVKAq" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                <div id="F4pfm5hbjlAbzWfq" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                  <div id="GUoeauUcCJQDiPH6" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(117.01507895% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                    <img src="/images/ed21b87c439459f6ad659abf1f2dd922.jpg" alt="Bright spotlight illuminate dark brick wall" loading="lazy" srcSet="/images/d9e9cca06a838439d1b1bef6453ff349.jpg 1114w, /images/ed21b87c439459f6ad659abf1f2dd922.jpg 2227w" sizes="(max-width: 375px) 100vw, (min-width: 375.05px) and (max-width: 480px) 100vw, (min-width: 480.05px) and (max-width: 768px) 100vw, (min-width: 768.05px) and (max-width: 1024px) 100vw, (min-width: 1024.05px) 100vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 42.72953576%', transform: 'translate(-50%, -42.72953576%) rotate(0deg)' }}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="dLX3STwT8vA1TRta" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
          <div id="JTgx7u7Qa1RKLRFN">
            <div id="pkXUoFRqAeXqm8WY" style={{ display: 'grid', position: 'relative', gridArea: '2 / 5 / 12 / 8' }}>
              <div id="znDzx9uq82zRePeS" style={{ zIndex: 6, pointerEvents: 'none' }}>
                <div id="jVeFEtTFjPFgGWIq" style={{ paddingTop: '100.62893082%', transform: 'rotate(0deg)' }}>
                  <div id="Rya0IAIE2JQ0fyBX" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="DzFMHmJNzzewcVTp" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="igOYci2YsAoWTPie" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="nsZy6HR8wdYqzIb9" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/ed69e1fc21fd820bab89430cca42ffe5.png" alt="Old CRT Computer Monitor Isolated on White" loading="lazy" srcSet="/images/bbd3269a7ed82ad84bc5727f32140f4c.png 602w, /images/ed69e1fc21fd820bab89430cca42ffe5.png 1204w" sizes="(max-width: 375px) 50.84395337vw, (min-width: 375.05px) and (max-width: 480px) 51.88158507vw, (min-width: 480.05px) and (max-width: 768px) 50.9551282vw, (min-width: 768.05px) and (max-width: 1024px) 52.11319929vw, (min-width: 1024.05px) 55.58741258vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="kn97wPNLMBp5ebrQ" style={{ display: 'grid', position: 'relative', gridArea: '3 / 3 / 7 / 9' }}>
                <div id="Y5LcI1eqCsmC32CV" style={{ zIndex: 7, pointerEvents: 'none' }}>
                  <div id="IEB9u15fnHqtsKzR" style={{ paddingTop: '72.45230624%', transform: 'rotate(0deg)' }}>
                    <div id="sQdVVdLscQIcBk27" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <svg id="WW13kZy0BOY3AsZa" viewBox="0 0 64.2357 46.5403" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                        <g id="T6qdfmR0UinOXuwB" style={{ transform: 'scale(1, 1)' }}>
                          <path id="p67cK7TH3ZivXnEb" d="M0,0 L64.23572962,0 L64.23572962,46.54026754 L0,46.54026754 Z" style={{ fill: '#118a8a', opacity: 1.0 }}></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div id="ZEoizaRHe7bZECcT" onClick={() => { withdraw() }} style={{ zIndex: 11, cursor: 'pointer' }}>
                  <div id="wbsFYrouTTMAhZru" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="ijwQUFZ1EPGENAGC" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <div id="q2WQJRamIYluW5eP" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                        <div id="blj1D8O18qO5BDjQ" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative', borderRadius: '0%/0%' }}>
                          <div id="dkjYOboa5UWifMU5" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                            <img src="/images/58b4c7c78f12ea4bfdd3ccd3beb6f146.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                          </div>
                          <svg id="Kgnz1Ci7mTPT3mgX" viewBox="0 0 75.63789161504565 75.63789161504566" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                            <g id="ApSEsYfDJTyTCdJJ" style={{ transform: 'scale(1, 1)' }}>
                              <g id="psqn3uAiM1kw17AO" style={{ clipPath: 'url(#M1fgINrPetHe0c81)' }}>
                                <clippath id="M1fgINrPetHe0c81">
                                  <path d="M0,0 L75.63789368,0 L75.63789368,75.63789368 L0,75.63789368 Z"></path>
                                </clippath>
                                <path id="Muveq9KX0nX1eh0c" d="M0,0 L75.63789368,0 L75.63789368,75.63789368 L0,75.63789368 Z" style={{ fill: 'none', stroke: 'transparent' }}></path>
                                <path id="oBThJfU6tD300DQE" d="M0,0 L75.63789368,0 L75.63789368,75.63789368 L0,75.63789368 Z" style={{ fill: 'none', strokeLinecap: 'round', vectorEffect: 'non-scaling-stroke', stroke: '#000000' }}></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div id="ZlD5SxsxfsZ2Mvy4" style={{ zIndex: 24 }}>
                        <div id="zwX8vilUbYQ08nZL" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                          <div id="KEne4nohIFkh8FBf" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                            <p id="zZsJapxwbcbPSxWJ" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.36363636em', textAlign: 'center' }}>
                              <span id="lcN86Y83UfOlX3it" style={{ color: '#fff1eb' }}>Unstake</span>
                              <br></br>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="GUakv73886zOAUPq" style={{ zIndex: 13 }}>
                  <div id="SFjNwcdsefuaMvci" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="hV5OzA3sVuXuB37p" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <div id="rdlnh5hpvDa9k64G" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                        <div id="xKWFY8Ui91jOkSfZ" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                          <div id="PnfDVXQzTRHLpaEe" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                            <img src="/images/2a8e700b74eb28c457d10dee91b08365.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="sAG6HMCqxXBPKKLc" style={{ zIndex: 12 }}>
                  <div id="E0Wurol29SySsHnK" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="IhnztiorOYqgLrws" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <div id="pGKfrUd8HizKoqj1" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                        <div id="izliJPv2JA5t4PfT" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                          <div id="jW7pdwcvzB8k0UbM" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                            <img src="/images/ac9dcbeba0c844ea46b011b0a0b8cfac.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="jnrVRUU53B2fCAHP" style={{ zIndex: 14 }}>
                  <div id="l8Aqv3CjVTZeVrjb" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="v9JQ4ckg7K0tCzuz" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <a id="PqYss2fMYlQ69nSL" target="_self" rel="noopener" href="/staking1" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                        <div id="VkWGN9ijVAPrtg3W" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                          <div id="WbU0EV7VWvieNOgZ" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            <div id="mv3nGx0fva9H7LdL" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                              <img src="/images/dded9dac34c3996e923537c84fc35805.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="hEnnesjQvbnuNdJz" style={{ zIndex: 20 }}>
                  <div id="PZrXwhL4nQx6TjAN" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="RAUOMvJvxwWTexs1" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                      <p id="jb9HpuJVsfxE6N9W" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.35493376em', textAlign: 'center' }}>
                        <span id="SYlOIvLldM1uDzt4" style={{ color: '#fff1eb' }}>Fuki ​7%</span>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
                <div id="bV8ZVBpbkUz4uAZl" style={{ zIndex: 16 }}>
                  <div id="md8bCVZQpDtAlmSe" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="UmJpMqMMlsSiOFeW" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <a id="Jhz0zNm57K0Nxebz" target="_self" rel="noopener" href="/staking2" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                        <div id="NQCNGGX8kXliVrMs" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                          <div id="IBGKbMlmkH1DbZ9M" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            <div id="Yw24sXA2s576bCNW" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                              <img src="/images/dded9dac34c3996e923537c84fc35805.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="FPnmhk4ZGR4mC228" style={{ zIndex: 22 }}>
                  <div id="vKgPANXP9AhoG2CJ" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="EjcIhZMpf9iCmVjd" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                      <p id="nZhBlEiXWPiUxJo0" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.36385252em', textAlign: 'center' }}>
                        <span id="kpO0CqFNgdlRdx5b" style={{ color: '#fff1eb' }}>Fuki 25% </span>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
                <div id="lbq8K7o6oawkjLTP" style={{ zIndex: 17, cursor: 'pointer' }}>
                  <div id="Yp4VnLCgV7xZTZWb" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="ik7DCVDMuqi5gMeW" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <div id="JLi0qQbrhm3FL35T" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                        <div id="wrNHzB8CTLB7nYx2" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                          <div id="SEkBSeUoSlNjETEp" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                            {wallets.map((wallet) => (
                              <img src="/images/e6506955abf1ac06f2ea164aadc889f7.png" onClick={() => { connect({ wallet }, { onSuccess: () => onWalletConnected() }) }} loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="D1ZqOerd2c4MvUky" style={{ zIndex: 23 }}>
                  <div id="yVRfpCdZ2PLqM9AL" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="neXnyDARM09GG5vH" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                      <p id="DN6SSQPqn4mnxENP" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.36363636em', textAlign: 'center' }}>
                        <span id="bpLW0MVqM5dbyGYj" style={{ color: '#fff1eb' }}>{wallet}</span>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
                <div id="eFXDw6lelKuAPXbY" style={{ zIndex: 15 }}>
                  <div id="AJgmVZUVzDiJQ590" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                    <div id="ILNKnma4dpkFOTqi" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                      <a id="Zaph26SxXzXepWOV" target="_self" rel="noopener" href="/staking3" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                        <div id="FvqqENUjEDLs38le" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                          <div id="XpAXRq22Oj6lq6z2" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            <div id="q0ikUNFR7h8AjfZx" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                              <img src="/images/dded9dac34c3996e923537c84fc35805.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="krJ1PeZrtS7reFNz" style={{ zIndex: 21 }}>
                  <div id="TmNIgOXPK21btcd8" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="q6a32FiEUQa2AFUS" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                      <p id="FOAZ2finbqk9NlMs" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.35488924em', textAlign: 'center' }}>
                        <span id="bybcFN00V2lcrcju" style={{ color: '#fff1eb' }}>Fuki 60%</span>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
                <div id="T43u1e6hvdOPtjQh" onClick={() => { disconnect({}, { onSuccess: () => onWalletDisconnected() }) }} style={{ cursor: 'pointer', display: butDisplay, position: 'relative', gridArea: '27 / 21 / 28 / 28' }}>
                  <div id="Lx3eASFtfM01wzTq" style={{ zIndex: 26 }}>
                    <div id="Mh0LY1CpbYMTKnli" style={{ paddingTop: '101.95934068%', transform: 'rotate(0deg)' }}>
                      <div id="OsOPxlm4YQlVnxft" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="AxZVsQSu7PQ6FkDT" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                          <div id="IP6bXpQEXbmylJty" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            <div id="iY2N8qOn6BdiH6Q9" style={{ width: 'calc(107.36605517% * max(1, var(--scale-fill, 1)))', height: 'calc(105.30281429% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                              <img src="/images/578ae1d80237b092123d534e357115a6.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 47.48211179%', transform: 'translate(-50%, -47.48211179%) rotate(0deg)' }}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="GqviADSBMeR7y5af" style={{ zIndex: 27 }}>
                    <div id="uLtG7tsBVHcB8tQr" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                      <div id="JSev41LwJbTR3dwc" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="eUISANMG3dWcg8cF" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.36363636em', textAlign: 'center' }}>
                          <span id="UgWBdspGZZSF52If" style={{ color: '#fff1eb' }}>Disconnect</span>
                          <br></br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="GoGORTLYxzrR7X6X" style={{ zIndex: 8 }}>
                <div id="cMNmC4QeBlm7njoa" style={{ paddingTop: '165.7899611%', transform: 'rotate(0deg)' }}>
                  <div id="s1IHdJ4lzhJHaomn" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="P7KMG1dpUs37efwK" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="m1YTWFYaEWmwKsj4" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="YYVebZjhJkbxk0gq" style={{ width: 'calc(165.7899611% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/3d65ebcb80104bad3364bad9184ac293.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="P2XxREr5GFW3GJqp" style={{ zIndex: 10 }}>
                <div id="J1X4WNhFFKUhO4DY" style={{ paddingTop: '49.33082652%', transform: 'rotate(0deg)' }}>
                  <div id="ZRj0FvcImUneO0o7" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="XzFRAiJkKAXJsBIV" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="Rt1z1mzGU5SzxlHL" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="TQzjW10SYS5T4kqm" style={{ width: 'calc(131.40183221% * max(1, var(--scale-fill, 1)))', height: 'calc(108.09160599% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/857bccfd4621a672e903a59181119b13.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '56.64905866% 50%', transform: 'translate(-56.64905866%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="wBjmx07a0TUzHNgz" style={{ zIndex: 9 }}>
                <div id="PO0JPKS5XtGMh6cz" style={{ paddingTop: '5.5961058%', transform: 'rotate(0deg)' }}>
                  <div id="CMH51lbVmPHpwET9" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="QxK7yCJmrl4PoT2n" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="Q6HHoR6vsfUVRtwL" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="VhQLk0Y49UOgLg9d" style={{ width: 'calc(203.49475648% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/24008629defa495be9b294630659238d.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '44.41201618% 50%', transform: 'translate(-44.41201618%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="dhV3p3fCQDn5y7oJ" style={{ zIndex: 18 }}>
            <div id="TjoaSriXMjCerKVj" style={{ paddingTop: '45%', transform: 'rotate(0deg)' }}>
              <div id="Fb3p3XRN7tPwY351" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                <div id="p3Vsg5fQJWCmnJjZ" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                  <div id="PwqlsIEPuON2jjh9" className="video_container" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div id="XVABth8gjRqzzOxb" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1))', height: 'calc(100% / min(1, var(--scale-fill, 1))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0 }}>
                      <img src="../videos/3b9adacc42a8135f580abbeea509cb5b.gif" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="MSWnB8FTDmgj24i4">
            <div id="p5vErsmSRsjWjNFP" style={{ display: 'grid', position: 'relative', gridArea: '4 / 3 / 6 / 7' }}>
              <div id="oFANS7V1QSuAiKdj" style={{ zIndex: 5 }}>
                <div id="CfATb7u1WH1o93o7" style={{ paddingTop: '71.125%', transform: 'rotate(0deg)' }}>
                  <div id="ODLF3GEVYObgOYyq" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="SAcwUwmTjllilqFY" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="QFmWBlVEm4UdYZh7" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="q3KsPjUXOuIcGRE1" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/380ecb7210faf3f64484635116a587e6.png" alt="Painting Frame Cutout" loading="lazy" srcSet="/images/253baf8c922c1fe99cc68d5937a439b5.png 508w, /images/380ecb7210faf3f64484635116a587e6.png 762w" sizes="(max-width: 375px) 23.64038919vw, (min-width: 375.05px) and (max-width: 480px) 24.12284612vw, (min-width: 480.05px) and (max-width: 768px) 23.69208101vw, (min-width: 768.05px) and (max-width: 1024px) 24.23053739vw, (min-width: 1024.05px) 25.84590655vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="b4adkyrn7x5PE2Nh" style={{ zIndex: 4 }}>
                <div id="uJ1cVGxcOkXNeAeG" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                  <div id="G133UzEl0Xo9nUaP" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="GZxZHCG0wjZF6W1x" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="bzw5ALyqQ0Dd2iOh" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="eRqY8veXpB7zGsT2" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/ddf45f9d7bf1a780d5c1e95ce4c74962.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="SxsrVJ1tSwpYzFEU" style={{ zIndex: 3 }}>
            <div id="Zi2po2Zfl9kr4V5w" style={{ paddingTop: '134.22818792%', transform: 'rotate(0deg)' }}>
              <div id="aHnGCpGi5laH52Fp" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                <div id="bUXNvmDLTpe7Hepa" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                  <div id="jO9HCcVrEKq07qTW" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div id="ns68j5ro1V0QBuo9" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                      <img src="/images/2c94c316da4a6fc722d65de6835b39a6.png" alt="Cactus" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="XMtUYKCOD1OyTybP" style={{ zIndex: 19 }}>
            <div id="czUSJ4pPp8siV08B" style={{ paddingTop: '142.60249554%', transform: 'rotate(0deg)' }}>
              <div id="GNMf0aS1ejBDjoPt" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                <div id="yhBTpjebVawMZ5VJ" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                  <div id="S4hVWr2jAn7fpiDn" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div id="JkITZOW30y3sCUIL" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                      <img src="/images/c377b17efa443b0bacdbb226f3374640.png" alt="Whiskey Sour Cocktail Illustration" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="vYOYARE87xLSgJRl" style={{ zIndex: 2 }}>
            <div id="N5sfhEfE1EOrgpAc" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="FTYS1yoLd92eqCAe" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                <div id="xkI9mnWy49tCdCMd" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                  <div id="p8WQnSnCzzpcqW15" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                    <img src="/images/3e2dee20fce2d4a0d56cec5ec9bc990a.png" alt="3d table for product display" loading="lazy" srcSet="/images/c6c613074b245db68c3697c2e6caaf21.png 1095w, /images/3e2dee20fce2d4a0d56cec5ec9bc990a.png 1824w" sizes="(max-width: 375px) 91.46666667vw, (min-width: 375.05px) and (max-width: 480px) 93.33333333vw, (min-width: 480.05px) and (max-width: 768px) 91.66666667vw, (min-width: 768.05px) and (max-width: 1024px) 93.75vw, (min-width: 1024.05px) 100vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="page-2" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
      <section id="VdeILkon1psweVJf" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
        <div id="aKI0wS7OtSyHdMdl" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
          <div id="kTSajshTOj0Nxj8x" style={{ zIndex: 0 }}>
            <div id="LlCbSsgxmy2TXvfj" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="ImIokLoTwYpsNuli" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                <div id="OKKB0QEOWNya9k7s" style={{ backgroundColor: '#9b7352', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}></div>
              </div>
            </div>
          </div>
          <div id="Nev0cJY7mPKEfdC7" style={{ zIndex: 1 }}>
            <div id="pe7HRff7XlsRnkkS" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="ix8kUhBtzshIGWn4" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                <div id="mpEvAEwD5Yhucyl0" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                  <div id="kIw3uVmnfpoiqvMQ" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(117.01507895% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                    <img src="/images/ed21b87c439459f6ad659abf1f2dd922.jpg" alt="Bright spotlight illuminate dark brick wall" loading="lazy" srcSet="/images/d9e9cca06a838439d1b1bef6453ff349.jpg 1114w, /images/ed21b87c439459f6ad659abf1f2dd922.jpg 2227w" sizes="(max-width: 375px) 173.97188473vw, (min-width: 375.05px) and (max-width: 480px) 155.26711691vw, (min-width: 480.05px) and (max-width: 768px) 130.23558512vw, (min-width: 768.05px) and (max-width: 1024px) 119.79278252vw, (min-width: 1024.05px) 100vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 42.72953576%', transform: 'translate(-50%, -42.72953576%) rotate(0deg)' }}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="plvX7Vf2FrLKtHzJ" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
          <div id="rnplIgVxKJsEqApo" style={{ zIndex: 3 }}>
            <div id="dhKXAKBFwFUvHIWu" style={{ paddingTop: '160.96579477%', transform: 'rotate(0deg)' }}>
              <div id="hBtVIuFM45bdVaVF" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                <div id="Dl4siDez94SkkEXs" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                  <div id="idoJAwnUhdqYyqFF" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div id="HMRx3mCuVxamqr1H" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                      <img src="/images/cbe3c08f0c2876fa8327c1ed3b253968.svg" alt="Electric Bulbs Billboard. Retro Arrow Light Frame" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="y77AlqzCid4FXdOu">
            <div id="csTTVYjNsZmkEQyf" style={{ display: 'grid', position: 'relative', gridArea: '3 / 3 / 5 / 5' }}>
              <div id="Hbmqhk4G9RETOeMQ" style={{ zIndex: 2 }}>
                <div id="cknmkBtOO5K6VWAu" style={{ paddingTop: '50.5%', transform: 'rotate(0deg)' }}>
                  <div id="Fl4mpWuRpnQCaViJ" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="gbPYrECDzo3rW1A7" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="hlgduly18myV6l97" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="ejIiMBFnDFjxskQn" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="/images/41f47f428ae934668ea01dcfa1fd6a19.png" alt="Vintage Billboard Label with Electric Bulbs" loading="lazy" srcSet="/images/0d40a743cee8ed173926d79883561fd3.png 1250w, /images/41f47f428ae934668ea01dcfa1fd6a19.png 1875w" sizes="(max-width: 375px) 73.8894459vw, (min-width: 375.05px) and (max-width: 480px) 75.39739378vw, (min-width: 480.05px) and (max-width: 768px) 74.05101174vw, (min-width: 768.05px) and (max-width: 1024px) 75.73398928vw, (min-width: 1024.05px) 69.89129037vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="CwdWQBLO94jyEhUJ" style={{ zIndex: 4 }}>
                <div id="kIJyDrwfAAzBqpU7" style={{ boxSizing: 'border-box', width: '300%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="T5dq7bMNN2RFcINL" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="UHx4Zugbf9XHa3lP" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '0.4832085em', textAlign: 'left', letterSpacing: '-0.016em' }}>
                      <span id="dtswMrDHOIMFkLh9" style={{ color: '#ffffff', fontWeight: 700 }}>My staked Fuki: {stakedFuki} </span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div id="Vx9NPAGIerWsKCCO" style={{ zIndex: 6 }}>
                <div id="iPcYmMsD3tlsIBG9" style={{ boxSizing: 'border-box', width: '200%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="G56aSqbHlQcyhFbc" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="Eg0viCLNOGCzvH0z" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '0.4832085em', textAlign: 'left', letterSpacing: '-0.016em' }}>
                      <span id="y1IuB5INOvdaL6ne" style={{ color: '#ffffff', fontWeight: 700 }}>My APY: {percent}</span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div id="JiU5wzn8HfKfJeYx" style={{ zIndex: 5 }}>
                <div id="J0mWfXUDNyVOSqP9" style={{ boxSizing: 'border-box', width: '300%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="YqGUao9bBR8JoIca" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="btvB0DXn4Iny0c8H" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '0.4832085em', textAlign: 'left', letterSpacing: '-0.016em' }}>
                      <span id="bpvmBvmKAHuiMp3Z" style={{ color: '#ffffff', fontWeight: 700 }}>Rewards: {reward}</span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div id="XC49D7rFAG9bMpiB" style={{ zIndex: 7 }}>
                <div id="H80PzYxC8GlULTvo" style={{ boxSizing: 'border-box', width: '300%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="JjNSja5veWoxbGUr" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="kQ2nZow0ri6YnKWs" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '0.4832085em', textAlign: 'left', letterSpacing: '-0.016em' }}>
                      <span id="dPP1xA0CiLzkX11o" style={{ color: '#ffffff', fontWeight: 700 }}>Fuki Balance: {fukiBalance}</span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div id="eZCVnmBqFzYkFZhR" style={{ zIndex: 8 }}>
                <div id="Y9cko4qoeleSE6fV" style={{ boxSizing: 'border-box', width: '250%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="w0XS94Nsi1cu2I3g" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="lL5bi5dsw434D1fe" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '0.4832085em', textAlign: 'left', letterSpacing: '-0.016em' }}>
                      <span id="HNkav3Mq7gNen6c9" style={{ color: '#ffffff', fontWeight: 700 }}>Unlock time: {unlockTime}</span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Staking;
