import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import { SuiClientProvider } from "@mysten/dapp-kit";
import { WalletProvider } from '@mysten/dapp-kit';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { networkConfig } from "./config/networkConfig.js";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <SuiClientProvider networks={networkConfig} defaultNetwork="mainnet">
          <WalletProvider autoConnect>
            <App />
          </WalletProvider>
        </SuiClientProvider>
      </QueryClientProvider>
  </React.StrictMode>
);
