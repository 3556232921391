import React from 'react';
import './index.css'
function Main() {
    return (
        <>
            <a id="page-1" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
            <section id="HCoMi8okURzQ2FUD" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0 }}>
                <div id="Hi6tq9QFfXPsMS7O" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
                    <div id="GYZxd7sCWpy1B0hJ" style={{ zIndex: 0 }}>
                        <div id="iuBNpeNH8xfW8GhC" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="OBnJ8rXtmzwMYyan" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="hl4LtYTGl6w8DZAT" style={{ backgroundColor: '#ffffff', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                    <div id="mGXVDDZGhrmHbl2t" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(118.72460938% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 0.54, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                        <img src="/images/709a12954d16be6059084bc0fe069b59.jpg" alt="An Empty Refrigerator. inside an Empty, Clean Refrigerator, a Refrigerator Compartment after Defrosting" loading="lazy" srcSet="/images/1edc4957f99701a612b41f81ff563f96.jpg 1276w, /images/709a12954d16be6059084bc0fe069b59.jpg 2552w" sizes="(max-width: 375px) 356.35032974vw, (min-width: 375.05px) and (max-width: 480px) 228.13217076vw, (min-width: 480.05px) and (max-width: 768px) 145.29681222vw, (min-width: 768.05px) and (max-width: 1024px) 124.64288586vw, (min-width: 1024.05px) 100vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="o2vct82pnNq76JpZ" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
                    <div id="nmhQzdnlHo4Ngh6o" style={{ zIndex: 18 }}>
                        <div id="oaR6C3SNBVY7jhsE" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="PBTXDmhoLAyLZHra" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                <p id="z2CTA7bWhrcxuzoi" style={{ color: '#545454', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047777em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                    <span id="UTM2oe453Kh3uqB9" style={{ color: '#545454' }}>FUKI</span>
                                    <br></br>
                                </p>
                                <p id="SVFHtcIRhu21XmP5" style={{ color: '#545454', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047777em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                    <span id="lPuMlncNeGU8mV2J" style={{ color: '#545454', fontStyle: 'normal', fontWeight: 400 }}>POWERED</span>
                                </p>
                                <p id="UeeVj2u0SOLC5eub" style={{ color: '#545454', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047777em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                    <span id="CrRBM2Kk2PRqrOW5" style={{ color: '#545454', fontStyle: 'normal', fontWeight: 400 }}>BY SUI</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="SDmbetXCdcNiMQ0l">
                        <div id="nbcdE1ko1cfCBYgv" style={{ display: 'grid', position: 'relative' }}>
                            <div id="tw8ztAt2LTbHdszs" style={{ zIndex: 1 }}>
                                <div id="nYoK2VPkqqLBFdcB" style={{ paddingTop: '28.5%', transform: 'rotate(0deg)' }}>
                                    <div id="bmXXc0uciiPin6Ja" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <a id="K3ShlgMVne7XJOrb" target="_blank" rel="noopener" href="https://dex.bluemove.net/swap/0x8120491d565ceacf20000e75b0829591ed3c9d2eb1d29c53dbe44947e3b5ae87::fuki::FUKI/0x2::sui::SUI" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                            <div id="OjugbNb0LQMv4gjC" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                <div id="JQyaBEqi78vKboME" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                    <div id="klcLTeuu6FGbgJnN" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                        <img src="/images/6358d3cb60add0b45b56a5a97bc4c6ac.svg" alt="Button Color Illustration" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id="b0ZayqVlU57huviE" style={{ zIndex: 17, pointerEvents: 'none' }}>
                                <div id="JvrRxijZ9oMwdkNL" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                    <div id="zUzsJtHjGxLfWj2O" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                        <p id="xoZDYyBabR8XrASa" style={{ color: '#545454', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39772886em', textAlign: 'center' }}>
                                            <span id="FeCZQkDRG2gqyuub" style={{ color: '#545454' }}>APE IN</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="KD4cu3gkWafGaBvn">
                        <div id="cgD9IXlm7Ju2J8BZ" style={{ display: 'grid', position: 'relative' }}>
                            <div id="awBJa1CduFGW55qK" style={{ zIndex: 2 }}>
                                <div id="Pi610VCl60cj5oRz" style={{ paddingTop: '28.5%', transform: 'rotate(0deg)' }}>
                                    <div id="UTKfZVIrBr1TXRPp" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <a id="bwH0NI8bmGpaNN5b" target="_blank" rel="noopener" href="https://dexscreener.com/sui/0x52a561689e33f25e429c1e3aa4b8ef6414e9b72a21f3600bf855ebf7fbcc62b5" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                            <div id="MRWMp8GTL5F9RPOu" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                <div id="AY0M3pnxMJoCNlLF" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                    <div id="PWmAPyCI4GgT4B8a" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                        <img src="/images/6358d3cb60add0b45b56a5a97bc4c6ac.svg" alt="Button Color Illustration" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id="SLx52A4lNCDD7URU" style={{ zIndex: 16, pointerEvents: 'none' }}>
                                <div id="hyVohMOb6Cu6scNF" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                    <div id="CccrPNkLLX1p8wkt" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                        <p id="ZtJemiC5CLdDSFhb" style={{ color: '#545454', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39772886em', textAlign: 'center' }}>
                                            <span id="Erdn5uaybA6Qz8um" style={{ color: '#545454' }}>DexScreener</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="VVgsv7JECgKIBxCA" style={{ zIndex: 10 }}>
                        <div id="SEEW2BeLB6uLHdiW" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                            <div id="gHF32laeTEeTQ1IV" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                <a id="QqeCcEdT6XwuIacb" target="_blank" rel="noopener" href="https://x.com/Fukigangsui" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                    <div id="iJ1xaco0CCZp9akb" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="icK1A78Znuh6B38K" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative', borderRadius: '0%/0%' }}>
                                            <div id="qPD2WJbqld3n1ZQz" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="/images/ab931841dcae4bd580cd367774480574.svg" alt="X Icon" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                            </div>
                                            <svg id="MiWiY2mvCfKauEMx" viewBox="0 0 65.00742826927248 65.00742826927248" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                                                <g id="QMOMHtdjwov6C2b9" style={{ transform: 'scale(1, 1)' }}>
                                                    <g id="b7SKCT2seeN7bQ1C" style={{ clipPath: 'url(#wrZu5AYhzdm26yXZ)' }}>
                                                        <clippath id="wrZu5AYhzdm26yXZ">
                                                            <path d="M0,0 L65.00743103,0 L65.00743103,65.00743103 L0,65.00743103 Z"></path>
                                                        </clippath>
                                                        <path id="czN91w1UsITR2VGi" d="M0,0 L65.00743103,0 L65.00743103,65.00743103 L0,65.00743103 Z" style={{ fill: 'none', stroke: 'transparent' }}></path>
                                                        <path id="NgiJlB4c33SbfSuc" d="M0,0 L65.00743103,0 L65.00743103,65.00743103 L0,65.00743103 Z" style={{ fill: 'none', strokeLinecap: 'butt', vectorEffect: 'non-scaling-stroke', stroke: '#000000' }}></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="LXHFRPactaZhvcYa" style={{ zIndex: 11 }}>
                        <div id="KAFSWd0THMmWU53Z" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                            <div id="TN6N3jGhg41jNhuZ" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                <a id="AM4MmvSIAN7nhXLQ" target="_blank" rel="noopener" href="https://t.me/fukionsui" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                    <div id="njV0Y0RjGWK6GWDx" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="MOyRD4CwTfQrdwn9" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="tpqjlETo6bwgkebx" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="/images/d3f5019a4ca6cfad5aacf4ba5e94f22d.svg" alt="Telegram Mobile Application Button" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="TjWvPjmdU6HawWdg" style={{ zIndex: 12 }}>
                        <div id="FmU3hXUeaZN0TN1I" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                            <div id="T7SgFCcmbEY8NSrB" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                <a id="AenWZM6qi6G5maRJ" target="_blank" rel="noopener" href="https://www.youtube.com/@Fukionsui" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                    <div id="l99hzPbWRE8Qj55o" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="L2IyUu1CmNV1HkFI" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="bGldggIul3aQlxvN" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="/images/887eedab6779a3b4df6f980abbb515ec.svg" alt="Simple Youtube Icon" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="ztROjQYjqfL44BKH" style={{ zIndex: 14 }}>
                        <div id="oxo5IRejGk6FNLxe" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                            <div id="BoJzMCIWbPF7s8Ee" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                <a id="Q6qfyFfiken7l4t8" target="_blank" rel="noopener" href="https://www.tiktok.com/@fukionsui?lang=en" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                    <div id="ObyHylUjC961Qgk8" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="qxRZ3TGWQ5v0z3jF" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="mTlAiBycN5t8pqOP" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="/images/8c6e195086474790529866df07f26d7f.svg" alt="Glitch Music" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="FJLC1Qdi0rtkJld5" style={{ zIndex: 13 }}>
                        <div id="z60K3Re4QKHK1k8f" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                            <div id="yorRMHLvIqlFDQHK" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                <a id="G7iVA4SmtQKcEpKe" target="_blank" rel="noopener" href="https://www.instagram.com/fukionsui/" style={{ pointerEvents: 'all' }} data-interstitial-link="">
                                    <div id="MZvLp8pPIDMxjSsi" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                        <div id="t5IK9Ipb5WdFRTGE" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                            <div id="X1q61vykk96pO7qw" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                <img src="/images/fb3b1b26e71911268c9ede3ddcc00cef.svg" alt="Camera Instagram Vector Icon" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="t98KcVhx5k6ZiyUi">
                        <div id="qMnoabXHnVjwInRS" style={{ display: 'grid', position: 'relative' }}>
                            <div id="pVMtNSiLt9r47pBJ" style={{ zIndex: 8 }}>
                                <div id="KUioBASOzpwE4wWs" style={{ paddingTop: '104.48398383%', transform: 'rotate(0deg)' }}>
                                    <div id="t4NX4mPIoZmcUpSk" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div id="Kk0Rq6uOsauqXREH" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                            <div id="X71bb819J2zYpENs" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                <div id="sDvpqB2I3qNYeLm6" style={{ width: 'calc(104.48398383% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                    <img src="/images/629aaac45fd505c632fdde0eb390d9b5.png" loading="lazy" srcSet="/images/a1f44bd980903f48e183aabf24790d02.png 384w, /images/629aaac45fd505c632fdde0eb390d9b5.png 640w" sizes="(max-width: 375px) 26.97563766vw, (min-width: 375.05px) and (max-width: 480px) 27.52616088vw, (min-width: 480.05px) and (max-width: 768px) 27.03462229vw, (min-width: 768.05px) and (max-width: 1024px) 27.64904552vw, (min-width: 1024.05px) 29.49231522vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '52.14577568% 50%', transform: 'translate(-52.14577568%, -50%) rotate(0deg)' }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="mMzMMeDtZZ360CkH" style={{ zIndex: 6 }}>
                                <div id="kxkr4OjHT5WQpB4y" style={{ paddingTop: '108.84353741%', transform: 'rotate(0deg)' }}>
                                    <div id="AOmXOeUqNqH1dmon" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div id="uKC57mkAkovZGjO3" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                            <div id="ZtOgcMz3QEQqkvc5" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                <div id="X8RdZMt3yirGddl6" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                    <img src="/images/efea529d94e833bf8f178d522e65b01a.svg" alt="Cute ice bucket" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="cpXybmKBpVREDHwu" style={{ display: 'grid', position: 'relative', gridArea: '4 / 2 / 7 / 7' }}>
                                <div id="M0RZTwr15ZN1amDl" style={{ zIndex: 9 }}>
                                    <div id="LFJttbZRMNd2GrQ5" style={{ paddingTop: '2.875%', transform: 'rotate(-15.57297086deg)' }}>
                                        <div id="PESBNKPwZVmEzUqU" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div id="yXdLQRZikTbx9DN6" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                <div id="IsV2h9kHuVzXpnIy" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                    <div id="fvLY6XVjaAA26qrr" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                        <img src="/images/470cce0784fb79ee6ccff07788061cde.svg" alt="Quarantine Stripe Illustration" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="UYhof6XFdoTeePz2" style={{ zIndex: 4 }}>
                                    <div id="Gcd9UttTQ47lyuyj" style={{ paddingTop: '183.94648829%', transform: 'rotate(0deg)' }}>
                                        <div id="DdmJLdA16WWKMHqK" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div id="ENpMQA9AlGAJINIP" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                <div id="cDmwpAzHfHQ43R17" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                    <div id="L4XJTEBMxZUNhM1E" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                        <img src="/images/cf2074e79f4ef8844d9a573ac646689b.svg" alt="Printable Outlined Milk Carton" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="wCpLsvUbgKcrw74n" style={{ zIndex: 7 }}>
                                    <div id="wX0dNJxRUo4AuGhL" style={{ paddingTop: '85.63636364%', transform: 'rotate(0deg)' }}>
                                        <div id="lBoF8JSWHkU11J9r" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div id="RJPnFID2E5oN7AaD" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                <div id="N4UU9GU0zpC12Hbj" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                    <div id="k1oxfyMEMz0HNI2P" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                        <img src="/images/7dad61f589908d29e7541276266abe9f.svg" alt="Broccoli Vector" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Cg3Sy1q5bFb2mkZZ" style={{ zIndex: 5 }}>
                                    <div id="sSijFSaBqYxDedav" style={{ paddingTop: '95.27272727%', transform: 'rotate(0deg)' }}>
                                        <div id="uZXUAiMTq8qnqFqb" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div id="nR0kSXfF3zEn0zhZ" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                <div id="jBLPthIDN7mp8Dsf" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                    <div id="vIJXQrtCnzBngelT" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                        <img src="/images/8da8e154276c3634d90bdbfc98a26f27.svg" alt="Tomato" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="MAFrnQO60Cvo7r8P" style={{ zIndex: 15 }}>
                                    <div id="AxVYXKxNUfQWWpXN" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                        <div id="Z03aveSxG7y4bp5y" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                            <p id="d3YfJLOOxLv53XlB" style={{ color: '#44b8ea', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39599627em', textAlign: 'center' }}>
                                                <span id="Mz28ihZJGVERy483" style={{ color: '#44b8ea' }}>$FUKI</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a id="page-2" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
            <section id="PB190VfyxhN1g57H" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
                <div id="SseCKOv4DDk4zJ72" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
                    <div id="NE1rf1d3Ap7ric9U" style={{ zIndex: 0 }}>
                        <div id="yOP7VXTVlGKP50px" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="JiXMEiJJ4cExr67k" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="bAdrvozkAvIFJBUQ" style={{ backgroundColor: '#ffffff', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                    <div id="kWs8JswJ5czj18AF" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(108.2750651% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 0.13, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                        <img src="/images/915f02ec59c549d9667b1aa68944ea99.jpg" alt="City park" loading="lazy" srcSet="/images/d2deab69d1766a9fe74e0a7082aa8e1b.jpg 1180w, /images/915f02ec59c549d9667b1aa68944ea99.jpg 2360w" sizes="(max-width: 375px) 371.85555556vw, (min-width: 375.05px) and (max-width: 480px) 304.04367666vw, (min-width: 480.05px) and (max-width: 768px) 235.10062174vw, (min-width: 768.05px) and (max-width: 1024px) 203.18262388vw, (min-width: 1024.05px) 100vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="GfbzzPPRhlr71xg0" style={{ zIndex: 1 }}>
                        <div id="XtCc9Hstbf1nqZiS" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <svg id="jZ4WY0hanc53hnqe" viewBox="0 0 379.4444 213.3333" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: 0.43, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(&#x27;data:image/png;base64,&#x27;)' }}>
                                <g id="gP1SxWTJ6o9Ev2Fi" style={{ transform: 'scale(1, 1)' }}>
                                    <path id="qq9aJlAkFmSZ5wFJ" d="M0,0 L379.44444444,0 L379.44444444,213.33333333 L0,213.33333333 Z" style={{ fill: '#44b8ea', opacity: 1.0 }}></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="wXUfe99v8Az7ZkeL" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
                    <div id="MsIJpjnmK0SqWAKR">
                        <div id="F7EsBrbFEmUGSPX5" style={{ display: 'grid', position: 'relative' }}>
                            <div id="iHRuf8jfVivYS4vk" style={{ zIndex: 16 }}>
                                <div id="hTF6angfq6C9ORXK" style={{ paddingTop: '204.75%', transform: 'rotate(0deg)' }}>
                                    <div id="s3Kex8kVASRyx7iz" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div id="aw4i9vqFSEUOJB6M" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                            <div id="d10CMhrAml2rC9P4" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                <div id="eyNvhn5XkNiMn0Pu" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                    <img src="/images/bc76f466709a91928fc6ceceecd6492f.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ZoIMLEsxWCvcHkpf">
                        <div id="FCEonenHNKteaOTo" style={{ display: 'grid', position: 'relative' }}>
                            <div id="e9xue4IC3pL4VPS2" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 4 / 4' }}>
                                <div id="uTYNBfP5D8x8iCaD" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 3 / 3' }}>
                                    <div id="cxSaOV2oLrgXay0Z" style={{ zIndex: 4 }}>
                                        <div id="Emhjt853nFI011Hq" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="JZKxookyUNmy5UeL" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                                <svg id="beuwkSYvT0mHtRmP" viewBox="0 0 64.0 64.0" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(\'data:image/png;base64,\')' }}>
                                                    <g id="N888KC0b2ipT0jrP" style={{ transform: 'scale(1, 1)' }}>
                                                        <path id="y2eFPDS7bQEVqFcl" d="M10,0 L54,0 C59.5228475,0 64,4.4771525 64,10 L64,54 C64,59.5228475 59.5228475,64 54,64 L10,64 C4.4771525,64 0,59.5228475 0,54 L0,10 C0,4.4771525 4.4771525,0 10,0 Z" style={{ fill: '#f3e306', opacity: 1.0 }}></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="FkZ9L6qQUTZB1uA6" style={{ zIndex: 5 }}>
                                        <div id="ag44sLAMqYnK4PQv" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="T7n4Lt2ANKGvfdtk" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                <p id="qlZJ4US1Ckbg7TwL" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center' }}>
                                                    <span id="SKvSTvDXviFZJTi0" style={{ color: '#000000' }}>TOTAL SUPPLY</span>
                                                </p>
                                                <p id="MyUU1BEJsxk1dzCr" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                                    <span id="kNT65T41NvFLR9Vd" style={{ color: '#000000' }}>1​ BILLION</span>
                                                    <br></br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="j47hG6rZtGEWAq6o" style={{ display: 'grid', position: 'relative', gridArea: '6 / 3 / 8 / 5' }}>
                                <div id="t3zIVKyazDNzzrFn" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 3 / 3' }}>
                                    <div id="iXhJDRsO9EVbiecL" style={{ zIndex: 10 }}>
                                        <div id="Hemj09rcOA7KgWgc" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="APFIWFBOPSzgXC0F" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                                <svg id="uBeNw2sNJ4EeDPfd" viewBox="0 0 64.0 64.0" style={{ width: '100%', height: '100%', opacity: '1.0', overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(\'data:image/png;base64,\')' }}>
                                                    <g id="ldgavAUHfRBHnxWJ" style={{ transform: 'scale(1, 1)' }}>
                                                        <path id="YjhgHyiBUNKucKii" d="M10,0 L54,0 C59.5228475,0 64,4.4771525 64,10 L64,54 C64,59.5228475 59.5228475,64 54,64 L10,64 C4.4771525,64 0,59.5228475 0,54 L0,10 C0,4.4771525 4.4771525,0 10,0 Z" style={{ fill: '#f3e306', opacity: '1.0' }}></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="ndV4vajM7C15muWi" style={{ zIndex: 11 }}>
                                        <div id="kt7etc92XMD01Nae" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="dh6kG1UGsdaXSXqk" style={{ opacity: '1.0', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                <p id="P0qQOhQwSkp7aC1Z" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center' }}>
                                                    <span id="jLuBhNPotfiE6DZd" style={{ color: '#000000' }}>LIQUIDITY</span>
                                                </p>
                                                <p id="vd9F9gCezZB28BGW" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                                    <span id="rAJS6iQ49kWWTFRe" style={{ color: '#000000' }}>60%​</span>
                                                    <br></br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="s0oxYsLsEQx9iQnD" style={{ display: 'grid', position: 'relative', gridArea: '3 / 6 / 5 / 8' }}>
                                <div id="oDpjAAGpD56VX2lY" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 3 / 3' }}>
                                    <div id="KyBDA8zW131ZPJbx" style={{ zIndex: 7 }}>
                                        <div id="B3FTnitgTEh88k5n" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="SkZFpYZbpyY0alw1" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                                <svg id="z67nDWl1ofMgYyfp" viewBox="0 0 64.0 64.0" style={{ width: '100%', height: '100%', opacity: '1.0', overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(\'data:image/png;base64,\')' }}>
                                                    <g id="fpo1ixJ8UjTrnyzF" style={{ transform: 'scale(1, 1)' }}>
                                                        <path id="IZ9v3YVI7bmu4b7J" d="M10,0 L54,0 C59.5228475,0 64,4.4771525 64,10 L64,54 C64,59.5228475 59.5228475,64 54,64 L10,64 C4.4771525,64 0,59.5228475 0,54 L0,10 C0,4.4771525 4.4771525,0 10,0 Z" style={{ fill: '#f3e306', opacity: '1.0' }}></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="WSilaAiSGbS4cjjg" style={{ zIndex: 8 }}>
                                        <div id="J6P1aGpJHch6VSsa" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="i3paWReVaioMSI4I" style={{ opacity: '1.0', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                <p id="IWo9DmSAzsqnjsSp" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center' }}>
                                                    <span id="kVwerrU6vwYRZOGO" style={{ color: '#000000' }}>AIRDROP</span>
                                                </p>
                                                <p id="HGOS3zqg7FCPl4im" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center' }}>
                                                    <span id="O9iAtvlwCCJmESUW" style={{ color: '#000000' }}>(rewarding ​community)</span>
                                                </p>
                                                <p id="SH7FuVbaZiF0qfJa" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                                    <span id="sgYNj3XAjTHk1TLv" style={{ color: '#000000' }}>25%​</span>
                                                    <br></br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="GuD5Uz86W29AO2PF" style={{ display: 'grid', position: 'relative', gridArea: '7 / 7 / 9 / 9' }}>
                                <div id="vCFLNiy5CSxYYczU" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 3 / 3' }}>
                                    <div id="efp5NX8ELL6cb0m5" style={{ zIndex: 13 }}>
                                        <div id="YtusFAZtQqBea0l2" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="jlgYuzyYttPD3jna" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                                <svg id="Phrg1wiQnj3wyyEx" viewBox="0 0 64.0 64.0" style={{ width: '100%', height: '100%', opacity: '1.0', overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(\'data:image/png;base64,\')' }}>
                                                    <g id="Li6JyzpiC1uNhXXu" style={{ transform: 'scale(1, 1)' }}>
                                                        <path id="YYu3ZZDmG5iSXdy1" d="M10,0 L54,0 C59.5228475,0 64,4.4771525 64,10 L64,54 C64,59.5228475 59.5228475,64 54,64 L10,64 C4.4771525,64 0,59.5228475 0,54 L0,10 C0,4.4771525 4.4771525,0 10,0 Z" style={{ fill: '#f3e306', opacity: '1.0' }}></path>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="TVzgVXnsETi1JwP8" style={{ zIndex: 14 }}>
                                        <div id="Lgwue5AFgm7uOdXR" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                            <div id="huy2RCR6e9ihtOgr" style={{ opacity: '1.0', display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                <p id="MyT9Ep1lVyfTG2y1" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center' }}>
                                                    <span id="busvChbcroeSqvDp" style={{ color: '#000000' }}>TREASURY</span>
                                                </p>
                                                <p id="PDnvbnw9qDjr9qmk" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center' }}>
                                                    <span id="ptnKIlXkwmGbFu7I" style={{ color: '#000000' }}>(​futu​re listings)</span>
                                                </p>
                                                <p id="qJJQv9HXmbrwyJx5" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                                    <span id="N52I9TXpoGF4foNz" style={{ color: '#000000' }}>15%</span>
                                                    <br></br>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="n97crLUJTMUhWzwE" style={{ zIndex: 19 }}>
                        <div id="S8JPBsyUkgDGctX0" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="tdq1UtVKTCg5B62K" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                <p id="ERjsuCExaWs4OxTh" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39047931em', textAlign: 'center', textTransform: 'none', letterSpacing: '0em' }}>
                                    <span id="IzbWBVK7ealvAzkS" style={{ color: '#000000', fontStyle: 'normal', fontWeight: 400 }}>TOKEN ADDRESS</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="FXourizORhVFYUTf">
                        <div id="KoKSbxUT4u8DRTFN" style={{ display: 'grid', position: 'relative' }}>
                            <div id="I0meeBGXz1lEo51F" style={{ zIndex: 17 }}>
                                <div id="hi7S8cqO7VOOi4ws" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                                    <div id="V4N1QqMUzKIAgiqR" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div id="NKWPs2rA7Pb1lFEi" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
                                            <div id="rDvvfIdUylSX42mw" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                <div id="BkMDvO1AKRlM1tPf" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                    <img src="/images/72da3ad288b7c832c06a874c2719e7e1.png" loading="lazy" srcSet="/images/a1f44bd980903f48e183aabf24790d02.png 384w, /images/72da3ad288b7c832c06a874c2719e7e1.png 768w" sizes="(max-width: 375px) 47.80511117vw, (min-width: 375.05px) and (max-width: 480px) 48.78072569vw, (min-width: 480.05px) and (max-width: 768px) 47.9096413vw, (min-width: 768.05px) and (max-width: 1024px) 48.99849678vw, (min-width: 1024.05px) 38.52270332vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Pt2SasUpq89WC7LY" style={{ zIndex: 18 }}>
                                <div id="HiyRjnbVFce4JxVN" style={{ paddingTop: '56.125%', transform: 'rotate(0deg)' }}>
                                    <div id="Of8Mm2opQHSuO7RO" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div id="PzonDZtGPYRqiKsy" style={{ width: '100%', height: '100%', opacity: '1.0' }}>
                                            <div id="dnRBvwYfXNuzsyq0" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                <div id="H9JRPynndIohlyIU" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: '1.0', animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                    <img src="/images/f8f2be8884ed86eec07660720eafa294.svg" alt="angry cartoon eyes" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a id="page-3" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
            <section id="VUhJu2YPnTPCHjhQ" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
                <div id="JAVZGggq0qfvXY8P" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
                    <div id="S24Qvk6Uub2QI1uE" style={{ zIndex: 0 }}>
                        <div id="SwVC2FlJeMQYHSou" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="h5WE2DYMdLOwVxta" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="E03hhVeWKSDQN1Yu" style={{ backgroundColor: '#ffffff', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                    <div id="pJ8ksikL8C9BPIyH" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(140.51302083% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 0.29, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                        <img src="/images/f3bc2d6813e8283209ee8d0bb783c3c0.png" alt="Cozy Child&#x27;s Bedroom with Playful Elements" loading="lazy" srcSet="/images/20e8fe5758db58c5ef3477699740c80e.png 1062w, /images/f3bc2d6813e8283209ee8d0bb783c3c0.png 2123w" sizes="(max-width: 375px) 100vw, (min-width: 375.05px) and (max-width: 480px) 100vw, (min-width: 480.05px) and (max-width: 768px) 100vw, (min-width: 768.05px) and (max-width: 1024px) 100vw, (min-width: 1024.05px) 100vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="UoDqrptOvPSXfdM2" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
                    <div id="SauakXt7erBn7Pat">
                        <div id="JxjkhhxnzqlZg7HH" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 4 / 6' }}>
                            <div id="LoY9LNFKynqI28ld" style={{ zIndex: 11 }}>
                                <div id="n4wehInE41fCwFfU" style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}>
                                    <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                        <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-RIGHT-0a852ff3-bde8-4e05-b13f-0ff7954775ca 500ms 100ms both paused' }}>
                                            <svg id="y0Y2roi8vm4LDDxr" viewBox="0 0 379.4444 39.2392" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: '1.0', overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                                                <g id="Hcn4F5BBtdTKqkMG" style={{ transform: 'scale(1, 1)' }}>
                                                    <path id="M4E8o4VQHwxiC5uP" d="M0,0 L379.44444444,0 L379.44444444,39.23915025 L0,39.23915025 Z" style={{ fill: '#f3e306', opacity: '1.0' }}></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="MZinALDTUIQ7TrGA" style={{ zIndex: 12 }}>
                                <div id="QQbhaOYyrM3mKnNu" style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}>
                                    <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                        <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-LEFT-94ac6c46-caf6-4895-a3fd-b7910b9c8782 500ms 100ms both paused' }}>
                                            <div id="aVxuqBSp7Usil9CF" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                                <p id="cRBH4RYtElSYnavo" style={{ color: '#000000', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39162909em', textAlign: 'center' }}>
                                                    <span id="YADqlYi8lqcJeoFF" style={{ color: '#000000' }}> $FUKI $FUKI $FUKI</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="UHdk1SzIMxXB9lZe">
                        <div id="CR0i87UxQ2qVrDDe" style={{ display: 'grid', position: 'relative', gridArea: '3 / 4 / 6 / 7' }}>
                            <div id="jRGK6ZndkgI1U1Pb" style={{ zIndex: 6 }}>
                                <div id="GGOWhQPOd9S8Lb4i" style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}>
                                    <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                        <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-RIGHT-07a4b2ed-c2d8-41a7-80d2-6f007f174412 500ms 100ms both paused' }}>
                                            <div id="DSVTPMiDe3tIX7Pr" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                                <p id="hslfahugOP8kjSZc" style={{ color: '#ff7828', fontFamily: 'YADoUIDNzaE-1', lineHeight: '0.80889752em', textAlign: 'center', letterSpacing: '-0.03em', textShadow: '0em 0.0375em 0.061875em rgba(0, 0, 0, 0.105000)' }}>
                                                    <span id="zAYePmMAMmWS90bB" style={{ color: '#ff7828' }}>s0​0n</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="RU7pATwEjzj975w2" style={{ display: 'grid', position: 'relative', gridArea: '3 / 4 / 6 / 6' }}>
                                <div id="doNvn665yJOENovb" style={{ zIndex: 4 }}>
                                    <div id="rX8COiTXRtnPQlgx" style={{ paddingTop: '205.12820513%' }}>
                                        <div id="NHAiZBuynuaSrPAG" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                                <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-RIGHT-df5a5649-6c47-4b6e-9eb0-9ba4589b3a7c 500ms 100ms both paused' }}>
                                                    <div id="JNHr0iJKm3FDbqaH" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                        <div id="ylM3YW1Y8ydPkCF0" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                            <div id="yNu2DiJZlyQM45lq" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                                <img src="/images/cefff97b651116841baaefc6ceb811f9.svg" alt="Smartphone Screen Mockup" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="V020GxfFggxupthb" style={{ zIndex: 5 }}>
                                    <div id="Awbr76Jx9fCzxGkZ" style={{ paddingTop: '208.8772846%' }}>
                                        <div id="kTP0kWnpVejAoN4Y" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                                <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-RIGHT-0c3a75b7-a649-4aed-8d41-1ff3ed1c3919 500ms 100ms both paused' }}>
                                                    <div id="oQUWKchWeRFka7f5" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                        <div id="j5l4A49pmiVdESBJ" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                            <div id="JWW19poxki2Hr4mL" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                                <img src="/images/e81048ed049a6fae3b02c183e3412138.png" loading="lazy" srcSet="/images/d0061eada91ae2c5ae0198b98b7d8c0f.png 184w, /images/e81048ed049a6fae3b02c183e3412138.png 368w" sizes="(max-width: 375px) 16.84739152vw, (min-width: 375.05px) and (max-width: 480px) 17.19121584vw, (min-width: 480.05px) and (max-width: 768px) 16.88422984vw, (min-width: 768.05px) and (max-width: 1024px) 17.26796234vw, (min-width: 1024.05px) 18.41915983vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="QU1gJxnJzUoahVQn" style={{ display: 'grid', position: 'relative', gridArea: '4 / 3 / 7 / 5' }}>
                                <div id="VrttusACrjaForDu" style={{ zIndex: 2 }}>
                                    <div id="JywdGo5mQMdCZ4vR" style={{ paddingTop: '100%' }}>
                                        <div id="QZX8bCq7f8qkmkcz" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                                <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-RIGHT-999b0874-cb56-441a-88a9-b1506f7bc6ca 500ms 100ms both paused' }}>
                                                    <div id="CECp1mhu75U98U9f" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                        <div id="QFVVXlYHpmXP9HYc" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                            <div id="wGaSi9fHVJ0XAG4J" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                                <img src="/images/72da3ad288b7c832c06a874c2719e7e1.png" loading="lazy" srcSet="/images/a1f44bd980903f48e183aabf24790d02.png 384w, /images/72da3ad288b7c832c06a874c2719e7e1.png 768w" sizes="(max-width: 375px) 35.23543263vw, (min-width: 375.05px) and (max-width: 480px) 35.9545231vw, (min-width: 480.05px) and (max-width: 768px) 35.31247804vw, (min-width: 768.05px) and (max-width: 1024px) 36.11503436vw, (min-width: 1024.05px) 38.52270332vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="KnzuTsgySnwjtOWS" style={{ zIndex: 3 }}>
                                    <div id="gQR9FGylRXdMdaba" style={{ paddingTop: '63.375%' }}>
                                        <div id="yo0EXNkg9ERU2eIN" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                            <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                                <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-RIGHT-aeceb9cb-7108-422b-b092-a7fbf2fd154e 500ms 100ms both paused' }}>
                                                    <div id="xPEePCljTsX44YyW" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                        <div id="kbURgaj019yzpgzA" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                            <div id="niCVvmLPXdfRE9wt" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                                <img src="/images/8772c1ddaa94fb8196570d79e7b6f5c8.svg" alt="Anime Mouth" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="XxfMmJ36fQYalmJK">
                        <div id="JPTcfYJwQsLAkMxI" style={{ display: 'grid', position: 'relative', gridArea: '5 / 3 / 7 / 5' }}>
                            <div id="qMM0li9OTe0DAyj9" style={{ zIndex: 8 }}>
                                <div id="p2ayE01ESTSDFpbV" style={{ paddingTop: '119.04761905%' }}>
                                    <div id="LT3vUTQ7b54JOZTK" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                            <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-LEFT-ac31b8ee-2252-432c-bdfe-ee56b2aaf0aa 500ms 100ms both paused' }}>
                                                <div style={{ width: '100%', height: '100%', transform: 'rotate(-11.08946754deg)' }}>
                                                    <div id="CGhTC13yZzMCp8dN" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                                        <div id="Ypuu3E7GxnzrSRHn" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                            <div id="DBg1cg66SrcvRiJK" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                                <img src="/images/f14ee5fb72107a7937f0d735a6d60f4e.svg" alt="road wooden sign" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="JfXiq7lO7DArAUg2" style={{ zIndex: 9 }}>
                                <div id="kbpPhOS5kuMJcYxo" style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}>
                                    <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                        <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-LEFT-ed1129e2-d319-4d87-aa67-994f72a7835a 500ms 100ms both paused' }}>
                                            <div style={{ width: '100%', height: '100%', transform: 'rotate(-11.07538054deg)' }}>
                                                <div id="Pj3AsevLKx7zJbBd" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                                    <p id="WvmBdZHiHvYfvHv1" style={{ color: '#f3e306', fontFamily: 'YAD02ZiRE08-0', lineHeight: '0.85724529em', textAlign: 'center', letterSpacing: '0em', textShadow: '0em 0.0375em 0.061875em rgba(0, 0, 0, 0.105000)' }}>
                                                        <span id="UgncPcBgWst3IHYC" style={{ color: '#f3e306' }}>mini</span>
                                                        <br></br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="yLLgNydVkdhXrszN" style={{ zIndex: 10 }}>
                                <div id="c8sBZODSCWAMe4JO" style={{ boxSizing: 'border-box', width: '100%', height: '100%' }}>
                                    <div className="animation_container" style={{ width: '100%', height: '100%' }}>
                                        <div className="animated" style={{ width: '100%', height: '100%', animation: 'pop-LEFT-bcc88dc6-8cb3-408d-945f-18fba6c49308 500ms 100ms both paused' }}>
                                            <div style={{ width: '100%', height: '100%', transform: 'rotate(-11.07538054deg)' }}>
                                                <div id="ypA7yTvnsh3xbp1K" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                                    <p id="J1ouP3qekHrm5kFp" style={{ color: '#f3e306', fontFamily: 'YADoUIDNzaE-1', lineHeight: '0.80287028em', textAlign: 'center', letterSpacing: '-0.03em', textShadow: '0em 0.0375em 0.061875em rgba(0, 0, 0, 0.105000)' }}>
                                                        <span id="PdMpaV6uupgBinc7" style={{ color: '#f3e306' }}>app</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a id="page-4" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
            <section id="trW5gSnAxYuqj39c" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0, marginTop: '-1px' }}>
                <div id="pn1x7c4gKltz9YH1" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
                    <div id="rvy4A4iR6YPIxqPA" style={{ zIndex: 0 }}>
                        <div id="mL2NvS4rUIsvXqk8" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="mOd3J7Ijc1NRBRRL" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                <div id="H2n94g8oKzNLA5eh" style={{ backgroundColor: '#afd4fa', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Twu2Ek3UMoef88oU" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
                    <div id="CP9ZE9qacZKht97w" style={{ zIndex: 2 }}>
                        <div id="LrzmuiLv5jbJD7hQ" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="kfY4PEYtfPsrzlb0" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                <p id="QoiLM6rQnYVKJt2S" style={{ color: '#f9db00', fontFamily: 'YACgEUY9wwg-0', lineHeight: '1.39597255em', textAlign: 'center' }}>
                                    <span id="raGDQUZSbiVQRSeW" style={{ color: '#f9db00' }}>ROADMAP</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="qjHVykhdePIeTmhj">
                        <div id="ku08T3jEjjkQDIib" style={{ display: 'grid', position: 'relative', gridArea: '3 / 4 / 11 / 8' }}>
                            <div id="mj8eCSR8RIfPXM4F" style={{ zIndex: 1 }}>
                                <div id="vOPecA4PJqpPNCbx" style={{ paddingTop: '88.625%', transform: 'rotate(0deg)' }}>
                                    <div id="sHrPOb94iDSp5pmZ" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                                        <div id="sbSKNeeT4OC7vbRk" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                                            <div id="LvCVAez8nWFwvCFZ" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                                                <div id="oFgDBDyLjkVMfG2I" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                                                    <img src="/images/c6750b437e5c8462e8b5791bd41b7d3d.svg" alt="Road Map Navigation" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="ujNdQWpgaVj51duH" style={{ zIndex: 5 }}>
                                <div id="qi7tbXIBbScQrU0U" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                                    <div id="fVE9QXf20wytuPgu" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                        <p id="sWzDWozhw3Qs7Zro" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39772886em', textAlign: 'center' }}>
                                            <span id="s6exoQAYdk6iFLzh" style={{ color: '#000000', fontWeight: 700 }}>1m </span>
                                        </p>
                                        <p id="DzMwhIsGv7XkxBPS" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39772886em', textAlign: 'center' }}>
                                            <span id="pxnR6cbyOhttK9ZU" style={{ color: '#000000', fontWeight: 700 }}>mcap</span>
                                            <br></br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="POftSeqPB2GG9unP" style={{ zIndex: 6 }}>
                        <div id="JBem8AAHgF9wUp50" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="GhnBxxV2utZrbKxD" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                <p id="dLV7M2EhCd6gQJWK" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39772886em', textAlign: 'center' }}>
                                    <span id="KhxSMn1bFAYMQeaM" style={{ color: '#000000', fontWeight: 700 }}>Cex listings</span>
                                    <br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="LswDIwdGmH8V1FQL" style={{ zIndex: 4 }}>
                        <div id="oNCDGrPyYKXhgfie" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="zho803dpI9iZmycT" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                <p id="rcHahNQYhBrtDzMH" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.3808763em', textAlign: 'center' }}>
                                    <span id="cEIsK1sxssABfXAZ" style={{ color: '#000000', fontWeight: 700 }}>Mini app </span>
                                </p>
                                <p id="NCh1C1hvuJQfho2a" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.3808763em', textAlign: 'center' }}>
                                    <span id="tXyyCOvGLkhUbe4Z" style={{ color: '#000000', fontWeight: 700 }}>release</span>
                                    <br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="W8vidrrjnlLMkvnZ" style={{ zIndex: 3 }}>
                        <div id="C7FE1Rrh6OY8XLfv" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                            <div id="iQSRtE5gu17SF3ot" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                                <p id="RDigOIja40TpB9iR" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39723854em', textAlign: 'center' }}>
                                    <span id="qp2qzlOzQ7BF5PkU" style={{ color: '#000000', fontWeight: 700 }}>Token </span>
                                </p>
                                <p id="yRDAiXVSmolxGkcd" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39723854em', textAlign: 'center' }}>
                                    <span id="bLaNcbUKMO7JKMRz" style={{ color: '#000000', fontWeight: 700 }}>launch</span>
                                    <br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Main;
