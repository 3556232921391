import React from 'react';
import '../stakingOne.css'
import { useConnectWallet, useWallets, useCurrentAccount, useSignAndExecuteTransaction, useDisconnectWallet, useSuiClient, useSuiClientQuery } from "@mysten/dapp-kit";
import { useEffect, useState } from "react";
import { COIN_TYPE, PACKAGE_ID, STAKING_CONFIG_ID, PERCENT_2 } from '../../config/constants';
import { Transaction } from "@mysten/sui/transactions";
function StakingTwo() {
  const [butDisplay, setButDisplay] = useState("none")
  const [wallet, setWallet] = useState("Wallet")
  const [weeklyRerward, setWeeklyRerward] = useState(0)
  const [stakeAmount, setStakeAmount] = useState(0)
  const [fukiBalance, setFukiBalance] = useState("0")
  const { mutate: connect } = useConnectWallet();
  const { mutate: disconnect } = useDisconnectWallet();
  const wallets = useWallets();
  const currentAccount = useCurrentAccount();
  const suiClient = useSuiClient()

  const fukiCoins  = useSuiClientQuery("getCoins", {
    owner: currentAccount?.address || '',
    coinType: COIN_TYPE
  },  { enabled: currentAccount != null } ).data?.data
  let coinObjectIds
  let lastCoinObjBalance
  
  if (fukiCoins && fukiCoins[0]) {
    coinObjectIds = fukiCoins.map(coin => coin.coinObjectId)
    console.log(fukiCoins)
    lastCoinObjBalance = fukiCoins[0].balance
  }

  const totalBalance = useSuiClientQuery("getBalance", {
    owner: currentAccount?.address || '',
    coinType: COIN_TYPE
  },  { enabled: currentAccount != null } ).data?.totalBalance

  async function onWalletConnected() {
    setButDisplay("grid")
    if (currentAccount) {
      const wallet = currentAccount.address.substring(0, 8)
      setWallet(wallet)
    }
  }

  function onWalletDisconnected() {
    setButDisplay("none")
    setWallet("Wallet")
  }

  function valueChanged(value) {
    const max = 1000000000000000n
    const min = 0
    if (value > max) {
      document.getElementById('8890').value = max.toString()
      value = max
    }
    if (value < min) {
      document.getElementById('8890').value = min.toString()
      value = min
    }
    const _weeklyReward = value > 0? ((value * PERCENT_2 / 400)).toFixed(4) : 0
    setWeeklyRerward(_weeklyReward)
    setStakeAmount(value)
  }

  const { mutate: signAndExecute } = useSignAndExecuteTransaction({
    execute: async ({ bytes, signature }) =>
      await suiClient.executeTransactionBlock({
        transactionBlock: bytes,
        signature,
        options: {
          showRawEffects: true,
          showEffects: true,
        },
      }),
  });

    function deposit() {
    if (!currentAccount) {
      alert("Connect wallet")
      return
    }

    if (Number(fukiBalance) == 0) {
      alert("You don't have FUKI to stake")
      return
    }
    const deposit = stakeAmount * 10**6
    const tx = new Transaction();
    // tx.setGasBudget(10000000)
    let finalCoinObj = coinObjectIds[0]
    if (coinObjectIds.length > 1 && Number(lastCoinObjBalance) < deposit) {
      const coinObjects = coinObjectIds.map(coin => tx.object(coin));
      coinObjects.shift();
      tx.mergeCoins(tx.object(coinObjectIds[0]), coinObjects)
    }
    console.log(finalCoinObj)
    const [stakeCoin] = tx.splitCoins(tx.object(finalCoinObj), [
      (deposit),
    ]);

    let success = false
    tx.moveCall({
      arguments: [
        stakeCoin,
        tx.object(STAKING_CONFIG_ID),
        tx.object('0x6'),
        tx.pure.u64(2)
      ],
      target: `${PACKAGE_ID}::staking::deposit`,
    });

    signAndExecute(
      {
        transaction: tx,
      },
      {
        onSuccess: (result) => {
          console.log(result)
          success = true
          alert(`You staked ${stakeAmount} FUKI \nGo to https://fukigang.com/staking to get stake details `)
        },
        onError: (error) => {
          console.log(error)
          success = false
        }
      },
    );
    return success
  }


  useEffect(() => {
    if (currentAccount) {
      const wallet = currentAccount.address.substring(0, 8)
      setWallet(wallet)
      setButDisplay('grid')
      setFukiBalance(totalBalance)
    }
  })

  return (
    <>
      <a id="page-1" aria-hidden="true" style={{ visibility: 'hidden' }}></a>
      <section id="PbN9emFs7ia7ueUZ" style={{ position: 'relative', overflow: 'hidden', display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 100rem auto', zIndex: 0 }}>
        <div id="rKIr9zWBRCjsajSf" style={{ gridArea: '1 / 1 / 2 / 4', display: 'grid', position: 'absolute', minHeight: '100%', minWidth: '100%' }}>
          <div id="UhIZjZkX5MlGT3al" style={{ zIndex: 0 }}>
            <div id="JlIcDNwU06dWiGRk" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
              <div id="HcrpCVPhgy3S5hEf" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                <div id="ZcRCP6qzHq22j2jd" style={{ backgroundColor: '#118a8a', opacity: 1.0, transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}></div>
              </div>
            </div>
          </div>
          <div id="TL9AXZmz0EWXiV75" style={{ zIndex: 4, left: 'max(0px, calc(100% + -195.00431084px))', right: '-17.30280634px', bottom: 0, position: 'absolute', transform: 'translateY(0.73057124%)' }}>
            <div id="ux8gVsGRFjU6R8Zs" style={{ paddingTop: '84.625%', transform: 'rotate(0deg)' }}>
              <div id="twcr4nNOPtzUEtNm" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                <div id="xiD5NethhRvRVBJw" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                  <div id="RuvPgRnw35F9mGP4" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                    <div id="pyNU1mZfJAOnJM2l" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                      <img src="../images/384b7d72be473aa077b464317c992af6.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="n7jyUkOIsGk7yWmF" style={{ display: 'grid', position: 'relative', gridArea: '1 / 2 / 2 / 3' }}>
          <div id="IQF3Ep2yt3EX14OE">
            <div id="dPVkGRVWgnbGXhoW" style={{ display: 'grid', position: 'relative' }}>
              <div id="aaSG147XZh7OMimw" style={{ display: 'grid', position: 'relative', gridArea: '2 / 2 / 3 / 3' }}>
                <div id="QjhCorpQeqNzHyxr" style={{ zIndex: 6 }}>
                  <div id="kul2i53Hi7MVNkwP" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                    <div id="Mu3ibxz3UNZwTogH" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="WspPUZKkGniUrB9S" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="Ye3seujJj305zpvt" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="../images/15f8b31291e525ac771cac7129da605c.png" alt="Windows 95 Retro Webcam Frame Overlay" loading="lazy" srcSet="../images/64cb86fefda7497428715b8284ce86cc.png 608w, ../images/15f8b31291e525ac771cac7129da605c.png 1013w" sizes="(max-width: 375px) 91.46666667vw, (min-width: 375.05px) and (max-width: 480px) 93.33333333vw, (min-width: 480.05px) and (max-width: 768px) 89.79804059vw, (min-width: 768.05px) and (max-width: 1024px) 83.69526113vw, (min-width: 1024.05px) 50.4867461vw" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="xBjWDCIHbOuYx0DU" style={{ display: 'grid', position: 'relative', gridArea: '3 / 3 / 4 / 4' }}>
                  <div id="XB8f7bYHtFkI16Gv" style={{ zIndex: 7 }}>
                    <div id="AgVkRg3ZwpZefyCo" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                      <svg id="mo8RU4eoOuC1Xeu4" viewBox="0 0 220.1903 124.6995" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                        <g id="R4p4Q97QpAGST5uw" style={{ transform: 'scale(1, 1)' }}>
                          <path id="Rp9TUaQkVs7N6jvy" d="M0,0 L220.19029233,0 L220.19029233,124.69954489 L0,124.69954489 Z" style={{ fill: '#ffffff', opacity: 1.0 }}></path>
                        </g>
                      </svg>
                      <svg id="SNRqqfot5mrdySNN" viewBox="0 0 134.8669 140.1423" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                        <g id="t6Cl5QGHnseaJP6B" style={{ transform: 'scale(1, 1)' }}>
                          <path id="yXJbSJ09SPv91AHs" d="M0,0 L134.86690843,0 L134.86690843,140.14229655 L0,140.14229655 Z" style={{ fill: '#ffffff', opacity: 1.0 }}></path>
                        </g>
                      </svg>
                      <svg id="JsAaxJa1oO5rmrPW" viewBox="0 0 99.4643 120.2553" preserveAspectRatio="none" style={{ width: '100%', height: '100%', opacity: 1.0, overflow: 'hidden', position: 'absolute', top: '0%', left: '0%', background: 'url(data:image/png;base64,)' }}>
                        <g id="aZjuRgcUXq8IQ431" style={{ transform: 'scale(1, 1)' }}>
                          <path id="LxMklZCxiwMMAC79" d="M0,0 L99.46434496,0 L99.46434496,120.25527451 L0,120.25527451 Z" style={{ fill: '#ffffff', opacity: 1.0 }}></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div id="fXkG4afEC6lBS0FQ" style={{ zIndex: 10 }}>
                    <div id="fSuCLcGAPwUFPDqU" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                      <div id="zLN1xfZ7xFcIP5Gy" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="zumRjPFlOJIBYDhg" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '1.39397485em', textAlign: 'center' }}>
                          <span id="GpzUrKHaf7mxE4uU" style={{ color: '#000000', fontWeight: 700 }}>Stake for 2 month</span>
                          <br></br>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="szMGYf5Pp9DA5ihu" style={{ zIndex: 11 }}>
                    <div id="aJ7FONkEAHXNxxHu" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                      <div id="YDORAyqMeHAtSarW" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="BoK9RZiARwaOZ39R" style={{ color: '#000000', fontFamily: 'YAFdJt8dAY0-b', lineHeight: '1.39397485em', textAlign: 'center' }}>
                          <span id="d40MDbnTP2kq7Hm3" style={{ color: '#000000', fontWeight: 700 }}>Enter FUKI Amount</span>
                          <br></br>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div id="Iur7dXhjNq9ASuHP" style={{ zIndex: 8 }}>
                    <input placeholder="" id="8890" onInput={(e) => {valueChanged(e.target.value)}} type="number" required className="input0" style={{ position: 'absolute', left: '0px', width: '150%', height: '100%'}}></input>
                  </div>
                  <div id="MYA23zVKslLDthSK" style={{ zIndex: 12, marginTop: '-20px'}}>
                    <div id="qQ1NEfRqS96UwCln" style={{ boxSizing: 'border-box', width: '200%', height: '100%', transform: 'rotate(0deg)' }}>
                      <div id="SsHhdvCo0kg8Msx4" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                        <p id="YGHqMqsapk2RRn0j" style={{ color: '#000000', font: 'YAFdJt8dAY0-b', lineHeight: '1.39226974em', textAlign: 'left', textTransform: 'none', letterSpacing: '0em' }}>
                          <span id="TzVOZpDa2Y5909wG" style={{ color: '#000000', fontStyle: 'normal', fontWeight: 700 }}>Est. weekly rewards ≈ {weeklyRerward}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="Pzzo8oaiQVgfC08V" style={{ zIndex: 9, cursor: 'pointer' }} onClick={() => {deposit()}}>
                    <div id="Hozv0APrjzWSCF2h" style={{ paddingTop: '31.875%', transform: 'rotate(0deg)' }}>
                      <div id="EW6jBX1apwV2Tkzc" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                        <div id="sutsphdZHYihmIOH" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                          <div id="fbyut9SqIdmAaD3x" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                            <div id="oTcoOBZAUSbVVAwv" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                              <img src="../images/cd5d311f6e018261a1f56dbc6394120b.svg" alt="Start button, Retro Computer Windows UI" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="bqx5L97Cgpb6bNnL" onClick={() => {window.location.replace('https://dex.bluemove.net/swap/0x2::sui::SUI/0x8120491d565ceacf20000e75b0829591ed3c9d2eb1d29c53dbe44947e3b5ae87::fuki::FUKI')}}>
            <div id="jJaQLgDXMjYcNc0d" style={{ display: 'grid', position: 'relative', cursor: 'pointer'}}>
              <div id="DMBMsVZi4WULzlTl" style={{ zIndex: 2, cursor: 'pointer'}}>
                <div id="YPDr3jWGWpG2tXhs" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                  <div id="Z3X9j1lB13xsWH8o" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="AM9gYkSBvyFkh6cS" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="EzR9SdfHdx1FELoF" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="uecvQPYRhJFxuFgE" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="../images/dded9dac34c3996e923537c84fc35805.png" loading="lazy" style={{ cursor: 'pointer', width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="YeQijaYUxMux8Xqx" style={{ zIndex: 3 }}>
                <div id="qNDyGDm4hkQYCNWI" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="Mfx73xOlY7KZhUCJ" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="Uyaq83tn5q6evDET" style={{ color: '#fff1eb', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39741757em', textAlign: 'center' }}>
                      <a id="hmIwllSap3J1nunb" target="_blank" rel="noopener" style={{ textDecorationLine: 'underline', color: '#fff1eb', pointerEvents: 'all' }} data-interstitial-link="">BUY ​MORE ​FUKI</a>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="tE3EbBcBp0CVfwhB" style={{ display: 'grid', position: 'relative', alignContent: 'center', cursor: 'pointer'}}>
            <div id="rAQiFpSKZI6wC6hI" style={{ display: 'grid', position: 'relative' }}>
              <div id="avweY2aftVjtPiqj" style={{ zIndex: 14 }}>
                <div id="PtkdN0T7jgrEqbwX" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                  <div id="HKYOo1GcNCoJoP4s" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="neNrazFxg3Hj9yQq" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="gjyl3rMaJ3V2dGxT" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="REHYCPV9xvHB64Pl" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          {wallets.map((wallet) => (
                            <img src="../images/e6506955abf1ac06f2ea164aadc889f7.png" onClick={() => { connect({ wallet }, { onSuccess: () => onWalletConnected() }) }} loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="AJsfFDXGwkWgEBvT" style={{ zIndex: 15 }}>
                <div id="i2wu6z7RfNqrODUj" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="BXjpmalCC8VMd7LE" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="eGSC8L0I0qgTi6NX" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39772886em', textAlign: 'center', alignContent: 'center' }}>
                      <span id="HhZuGNRHXFdcGgSI" style={{ color: '#ffffff' }}>{wallet}</span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="VMXkuJ3ga6NfJ5HR" style={{cursor: 'pointer'}} onClick={() => { disconnect({}, { onSuccess: () => onWalletDisconnected() }) }}>
            <div id="qEWd97vZXYlVrz3J" style={{ display: butDisplay, position: 'relative' }}>
              <div id="QJ1sBkgu1KnIl8JT" style={{ zIndex: 17 }}>
                <div id="sJ5fKSmfL1hNoDjH" style={{ paddingTop: '100%', transform: 'rotate(0deg)' }}>
                  <div id="IHq2nNambdIDxmWq" style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' }}>
                    <div id="JwMMPixznlI2ngVx" style={{ width: '100%', height: '100%', opacity: 1.0 }}>
                      <div id="IoTX0jYQWjFBJFzR" style={{ transform: 'scale(1, 1)', width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                        <div id="VaPblJvrVxMm4zg7" style={{ width: 'calc(100% * max(1, var(--scale-fill, 1)))', height: 'calc(100% / min(1, var(--scale-fill, 1)))', position: 'absolute', top: '50%', left: '50%', opacity: 1.0, animation: 'pulse 1.5s ease-in-out infinite' }}>
                          <img src="../images/578ae1d80237b092123d534e357115a6.png" loading="lazy" style={{ width: '100%', height: '100%', display: 'block', objectFit: 'cover', objectPosition: '50% 50%', transform: 'translate(-50%, -50%) rotate(0deg)' }}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="ZH9m94dW9SmTCfIc" style={{ zIndex: 18 }}>
                <div id="KUGXEYrg2haBzDKr" style={{ boxSizing: 'border-box', width: '100%', height: '100%', transform: 'rotate(0deg)' }}>
                  <div id="Q1D4QowNUi0vK5OC" style={{ opacity: 1.0, display: 'flex', boxSizing: 'border-box', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <p id="H6kEj03N7wQwUfTg" style={{ color: '#ffffff', fontFamily: 'YAFdJt8dAY0-0', lineHeight: '1.39772886em', textAlign: 'center' }}>
                      <span id="GxsI6QCysFd8Qdel" style={{ color: '#ffffff' }}>Disconnect</span>
                      <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StakingTwo;
